import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
    connect(){
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this)
        })
    }

    end(event) {
        let data = new FormData()
        let id = event.item.dataset.id
        data.append("position", event.newIndex + 1)
        console.log(data)

        let url = this.data.get("url").replace(":id", id)
        fetch( url, { method: "PATCH", headers: { "X-CSRF-Token": document.querySelector("[name='csrf-token']").content }, body: data, });
        }
}