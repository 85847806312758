import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'selection' ]
    change(event) {
        let selection = event.target.selectedOptions[0].value
        if (selection == "single") {
            this.selectionTarget.style.display = "none";
        } else if(selection == "bulk") {
            this.selectionTarget.style.display = "block";
        }

    }
}
